import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import Link from 'next/link';

import CloudinaryImage from '../common/CloudinaryImage';

import Container from './Container';

export default function ReadyToScale({ ready, setModalCall = false }) {
  return (
    <div className="bg-[#FAFAFA]">
      <Container className="ml-0 pl-0">
        <div className="grid grid-cols-12 md:gap-10 gap-5">
          <div className=" lg:col-span-5 col-span-5 bg-no-repeat bg-[length:100%_100%] bg-left relative">
            {ready?.bgImage?.data?.attributes?.url && (
              <CloudinaryImage
                backendImgUrl={ready?.bgImage?.data?.attributes?.url}
                alt={ready?.bgImage?.data?.attributes?.alternativeText}
                type="smallimg"
                layout="fill"
                objectFit="cover"
                className="w-full h-full absolute top-0 bottom-0 left-0 right-0"
              />
            )}
            {ready?.image?.data?.attributes?.url && (
              <CloudinaryImage
                backendImgUrl={ready?.image?.data?.attributes?.url}
                alt={ready?.image?.data?.attributes?.alternativeText}
                type="smallimg"
                className="w-9/12 h-full relative z-1"
              />
            )}
          </div>
          <div className="lg:col-span-7 col-span-7 flex items-center">
            <div>
              {ready?.titleEditor && (
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  className={`blue_gradient_text  font-[400] xl:text-[53px] md:text-[32px] text-[26px] title_hd leading-tight ${ready?.titleEditor && 'xl:mb-[24px] mb-[0px]'}`}
                  source={ready?.titleEditor}
                >
                  {ready?.titleEditor}
                </ReactMarkdown>
              )}
              <div
                className={`md:w-6/12 w-full h-[1px] bg-[#DADADA] ${ready?.Name && 'xl:mb-[24px] mb-[5px]'}`}
              ></div>
              <div className="md:text-[24px] text-[16px] font-bold text-[#000000]">
                {ready?.Name}
              </div>
              <p className="md:text-[16px] text-[14px] text-[#111111] font-[400]">
                {ready?.Designation}
              </p>
              <div className="">
                {ready?.Btn_link ? (
                  <Link
                    href={ready?.Btn_link}
                    className="mt-[35px] bg-[#FFA030] text-[#000D3A] border border-[#fff] group h-min relative rounded-[4px] overflow-hidden inline-block px-14 py-2 shadow-[10px_20px_40px_0px_#00000033]"
                  >
                    <span className="bg-[#fff] absolute top-0 left-0 flex w-full h-0 mb-0 transition-all duration-200 ease-out transform translate-y-0 group-hover:h-full opacity-1"></span>
                    <span className="lg:text-[24px] text-[14px] group-hover:text-black flex items-center font-[600] relative">
                      {ready?.btn_text}
                    </span>
                  </Link>
                ) : (
                  <button
                    onClick={() => setModalCall(ready?.btn_text)}
                    className="md:mt-[35px] mt-[20px]  bg-[#FFA030] text-[#000D3A] border border-[#fff] group h-min relative rounded-[4px] overflow-hidden inline-block px-14 py-2 shadow-[10px_20px_40px_0px_#00000033]"
                  >
                    <span className="bg-[#fff] absolute top-0 left-0 flex w-full h-0 mb-0 transition-all duration-200 ease-out transform translate-y-0 group-hover:h-full opacity-1"></span>
                    <span className="lg:text-[24px] text-[14px] group-hover:text-black flex items-center font-[600] relative">
                      {ready?.btn_text}
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
